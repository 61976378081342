'use client';

import { FC } from 'react';
import Link from 'next/link';

import { INextLink } from './types';

const NextLink: FC<INextLink> = ({ children, href, title = '', ...props }) => {
  return (
    <Link href={href} title={title} {...props}>
      {children}
    </Link>
  );
};

export default NextLink;
