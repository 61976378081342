import { FC } from 'react';
import Image from 'next/image';

import { INextImage } from 'components/atoms/NextImage/types';

const NextImage: FC<INextImage> = ({
  width,
  height,
  blurDataURL,
  src,
  alt
}) => {
  return (
    <Image
      src={src}
      width={width}
      height={height}
      blurDataURL={blurDataURL}
      quality={100}
      style={{ objectFit: 'contain' }}
      alt={alt}
    />
  );
};

export default NextImage;
