'use client';

import { FC, Fragment } from 'react';

import { IComponentRepeaterProps } from './type';

const ComponentRepeater: FC<IComponentRepeaterProps> = ({
  count,
  component
}) => {
  const repeatedComponents = new Array(count)
    .fill('')
    .map((_, index) => <Fragment key={index}>{component}</Fragment>);

  return <>{repeatedComponents}</>;
};

export default ComponentRepeater;
