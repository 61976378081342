import styled from 'styled-components';
import Row from 'antd/lib/row';

export const EmptyRowStyle = styled(Row)`
  display: flex;
  align-items: center;
  flex-direction: column !important;
  justify-content: center;
  height: 500px;
`;
