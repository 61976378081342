import { FC } from 'react';

import NextImage from 'components/atoms/NextImage';
import { ImageStyleWrapper } from './styles';
import { IImageProps } from './types';

const Image: FC<IImageProps> = ({
  width,
  height,
  border = false,
  ...restProps
}) => {
  return (
    <ImageStyleWrapper width={width} height={height} data-border={border}>
      <NextImage width={width} height={height} {...restProps} />
    </ImageStyleWrapper>
  );
};

export default Image;
