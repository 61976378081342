import styled from 'styled-components';

import { ITagStatusProps } from './types';
import { sizes } from 'styles/variables.module';

export const TagStatusStyleWrapper = styled.div<Omit<ITagStatusProps, 'text'>>`
  text-align: center;
  padding: ${sizes.sp3xs} ${sizes.sps};
  border-radius: ${sizes.sps};
  ${props => `
      background-color: ${props.color};
  `}
`;
