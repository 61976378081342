import AntdPopover from 'antd/lib/popover';
import { FC } from 'react';

import { IPopoverProps } from './types';

const Popover: FC<IPopoverProps> = ({ ...props }) => {
  return <AntdPopover {...props} />;
};

export default Popover;
