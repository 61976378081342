'use client';

import { FC } from 'react';

import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import { IEmptyProps } from './types';
import { EmptyRowStyle } from './styles';

const Empty: FC<IEmptyProps> = ({
  text,
  subText,
  icon = null,
  content = null,
  wrapperStyle = {}
}) => {
  return (
    <EmptyRowStyle style={{ ...wrapperStyle }}>
      {icon || <Icon name="emptyState" width={160} height={160} />}

      <BodyText size="large" fontWeight="bold" text={text} />
      {subText && (
        <BodyText
          size="small"
          fontWeight="regular"
          type="secondary"
          text={subText}
        />
      )}
      {content}
    </EmptyRowStyle>
  );
};

export default Empty;
