const priceCounterWithTrialDays = (
  price: number,
  monthlyPrice: number,
  trialDays: number
) => {
  const freeApp = price === 0;
  const haveTrialDays = trialDays > 0;
  const monthlyOrNot = monthlyPrice === 0 && price > 0;

  return {
    freeApp,
    monthlyOrNot,
    haveTrialDays
  };
};

export default priceCounterWithTrialDays;
