export enum TagStatusTypesEnum {
  LIVE = 'LIVE',
  NOT_INSTALLED = 'NOT_INSTALLED',
  FREE = 'FREE',
  NOT_FREE = 'NOT_FREE',
  MOST_HELPFUL_REVIEW = 'MOST_HELPFUL_REVIEW',
  NOT_HELPFUL_REVIEW = 'NOT_HELPFUL_REVIEW'
}

export enum AppTypeEnum {
  NATIVE = 'NATIVE',
  CUSTOM = 'CUSTOM'
}
