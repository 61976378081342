import { FC } from 'react';

import { IRate } from './types';
import { RateStyle } from './styles';

const Rate: FC<IRate> = ({ ...props }) => {
  return <RateStyle {...props} />;
};

export default Rate;
