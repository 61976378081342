import Skeleton from 'antd/lib/skeleton';
import styled from 'styled-components';

import { borderRadiusMedium } from 'styles/variables.module';

export const ApplicationCardSkeletonStyle = styled(Skeleton.Input)`
  &&& {
    width: 100%;
    height: 180px;
    border-radius: ${borderRadiusMedium};
  }
`;
