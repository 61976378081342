import styled from 'styled-components';
import Row from 'antd/lib/row';

import { media, sizes } from 'styles/variables.module';

export const ApplicationsListStyleWrapper = styled.div`
  overflow: hidden;
`;

export const ApplicationsListRowStyle = styled(Row)`
  padding-top: ${sizes.spm};

  ${media.sm(`
     padding-top: ${sizes.sp2xl}
  `)}
`;
