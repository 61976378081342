import { RateProps } from 'antd/lib/rate';

export enum RateSizeEnum {
  small = '15px',
  medium = '18px',
  large = '25px'
}

export interface IRate extends RateProps {
  size?: keyof typeof RateSizeEnum;
}
