'use client';

import { FC } from 'react';
import Col from 'antd/lib/col';

import ComponentRepeater from 'components/atoms/ComponentRepeater';
import ApplicationCardSkeleton from 'components/atoms/ApplicationCardSkeleton';
import { SCREEN_SIZES_FOR_CARDS } from './constants';
import { IApplicationCardsSkeletonProps } from './types';
import { ApplicationCardsSkeletonRowWrapper } from './styles';

const ApplicationCardsSkeleton: FC<IApplicationCardsSkeletonProps> = ({
  cardCount = 12
}) => {
  return (
    <ApplicationCardsSkeletonRowWrapper gutter={[24, 16]}>
      <ComponentRepeater
        component={
          <Col {...SCREEN_SIZES_FOR_CARDS}>
            <ApplicationCardSkeleton />
          </Col>
        }
        count={cardCount}
      />
    </ApplicationCardsSkeletonRowWrapper>
  );
};

export default ApplicationCardsSkeleton;
