import styled from 'styled-components';

import { borderRadiusBase, colors } from 'styles/variables.module';
import { IImageStyleWrapperProps } from './types';

export const ImageStyleWrapper = styled.div<IImageStyleWrapperProps>`
  border-radius: ${borderRadiusBase};
  background-color: white;
  overflow: hidden;

  > img {
    border-radius: ${borderRadiusBase};
    transform: scale(0.9);
  }

  ${({ width, height }) => `
        width: ${width}px;
        height: ${height}px;
        &[data-border='true'] {
            border: 1px solid ${colors.gray_4};
        }
     `}
`;
