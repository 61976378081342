import Card from 'antd/lib/card';
import styled from 'styled-components';

import Rate from 'components/atoms/Rate';
import {
  borderRadiusMedium,
  colors,
  sizes,
  media
} from 'styles/variables.module';

export const ApplicationCardStyleWrapper = styled(Card)`
  && {
    background: ${colors.gray_1};
    cursor: pointer;
    border-radius: ${borderRadiusMedium};
    transition: 0.3s;

    .ant-card-body {
      padding: ${sizes.spm};
    }

    &:hover {
      background: ${colors.primary_1};
      border: 1px solid ${colors.primary_4};

      .NOT_FREE {
        transition: 0.3s;
        background: ${colors.primary_2};
        color: ${colors.primary_6};
      }

      .FREE {
        transition: 0.3s;
        background: ${colors.green_2};
        color: ${colors.green_6};
      }
    }

    ${media.sm(`
     &:hover {
      background: unset;
      border: unset;
    }
  `)}

    ${media.sm(` 
      background: transparent;
      border-radius: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid ${colors.gray_4};
      &:hover {
        background: unset;
      }
      .ant-card-body {
        padding: ${sizes.spm} 0 !important;
       }
    `)}
  }
`;

export const PopularAppIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 28px;
`;

export const TagStatusContainer = styled.div`
  width: fit-content;
`;

export const ApplicationCardContainer = styled.div`
  display: grid;
  grid-template-columns: ${sizes.sp6xl} 1fr;
  grid-template-rows: auto 1fr;
  gap: ${sizes.spm} ${sizes.sp2xm};
  grid-template-areas:
    'applicationLogo applicationNameAndPrice'
    'applicationDescription applicationDescription'
    'applicationDescription applicationDescription';

  ${media.sm(` 
    grid-template-areas:
    'applicationLogo applicationNameAndPrice'
    '. applicationDescription'
    '. applicationDescription';
    gap: ${sizes.sps} ${sizes.sp2xm};
  `)}
`;

export const ApplicationLogo = styled.div`
  grid-area: applicationLogo;
`;

export const ApplicationNameAndPrice = styled.div`
  grid-area: applicationNameAndPrice;
  display: flex;
  flex-direction: column;
  gap: ${sizes.sps};
`;

export const ApplicationDescription = styled.div`
  grid-area: applicationDescription;
  display: flex;
  flex-direction: column;
  gap: ${sizes.spXl};

  ${media.sm(`
     gap: ${sizes.spXl};
  `)}
`;

export const ApplicationListRate = styled(Rate)`
  margin-left: ${sizes.sp2xs};
`;

export const AppPriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${sizes.sps};
`;

export const AppFreeTrialContainer = styled.span`
  color: #595959;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
`;
