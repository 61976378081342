import styled from 'styled-components';

import DisplayText from 'components/atoms/DisplayText';
import { sizes } from 'styles/variables.module';

export const HeadingCategoryStyleWrapper = styled(DisplayText)`
  && {
    margin-bottom: ${sizes.sp2xs};
  }
`;

export const SectionTitleStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizes.sp2xs};
`;
