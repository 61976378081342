import { FC } from 'react';

import { ITagStatusProps } from './types';
import BodyText from 'components/atoms/BodyText';
import { TagStatusStyleWrapper } from './styles';
import { TAG_STATUS_COLOR } from './constants';

const TagStatus: FC<ITagStatusProps> = ({
  text,
  minWidth = 'auto',
  status = 'LIVE'
}) => {
  return (
    <TagStatusStyleWrapper
      status={status}
      style={{ minWidth: minWidth && `${minWidth}px` }}
      color={TAG_STATUS_COLOR[status].backgroundColor}
      className={status}
    >
      <BodyText
        size="extraSmall"
        fontWeight="regular"
        color={TAG_STATUS_COLOR[status].color}
      >
        {text}
      </BodyText>
    </TagStatusStyleWrapper>
  );
};

export default TagStatus;
