'use client';

import { FC, useEffect, useRef } from 'react';

import { IInfiniteScrollProps } from './types';
import { mainFooterId } from 'common/constants/common';

const InfiniteScroll: FC<IInfiniteScrollProps> = ({
  children,
  next,
  loading = false,
  hasMore = false,
  loader,
  scrollTarget
}) => {
  const target = useRef<any>();

  const handleScroll = async () => {
    const infiniteScrollTarget = target.current;
    const footerHeight =
      document.getElementById(mainFooterId)?.offsetHeight || 0;

    let isFetch: boolean;

    if (scrollTarget) {
      const { scrollTop, scrollHeight, clientHeight } = infiniteScrollTarget;
      isFetch =
        +scrollTop.toFixed() + +clientHeight.toFixed() >=
        +scrollHeight.toFixed();
    } else {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = infiniteScrollTarget;
      isFetch =
        +innerHeight.toFixed() + +scrollTop.toFixed() >=
        +offsetHeight.toFixed() - footerHeight;
    }

    if (isFetch && hasMore && !loading) {
      await next();
    }
  };

  useEffect(() => {
    target.current = scrollTarget
      ? document.querySelector(scrollTarget)
      : window;

    if (target.current) {
      target.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (target.current) {
        target.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollTarget, handleScroll]);

  return (
    <>
      {children} {loading && loader}
    </>
  );
};

export default InfiniteScroll;
