import { ITagStatusDataConstantModel } from './types';
import { TagStatusTypesEnum } from 'common/globalTypes/enums/status';
import { KeyValuePair } from 'common/globalTypes/utilityTypes/common';

export const TAG_STATUS_COLOR: KeyValuePair<ITagStatusDataConstantModel> = {
  [TagStatusTypesEnum.LIVE]: {
    backgroundColor: '#EEFFDD',
    color: '#389E0D'
  },
  [TagStatusTypesEnum.NOT_INSTALLED]: {
    backgroundColor: '#FFFBE6',
    color: '#FA8C16'
  },
  [TagStatusTypesEnum.FREE]: {
    backgroundColor: '#F6FFED',
    color: '#52C41A'
  },
  [TagStatusTypesEnum.NOT_FREE]: {
    backgroundColor: '#F0F7FF',
    color: '#235DF4'
  },
  [TagStatusTypesEnum.MOST_HELPFUL_REVIEW]: {
    backgroundColor: '#F0F7FF',
    color: '#235DF4'
  },
  [TagStatusTypesEnum.NOT_HELPFUL_REVIEW]: {
    backgroundColor: '#FFF1F0',
    color: '#F5222D'
  }
};
