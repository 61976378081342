import { KeyValuePair } from 'common/globalTypes/utilityTypes/common';

export const SCREEN_SIZES_FOR_CARDS: KeyValuePair<number> = {
  xs: 24,
  sm: 12,
  md: 24,
  lg: 12,
  xl: 8,
  xxl: 6
};
